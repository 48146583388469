<template>
  <div
    ref="survey"
    class="os-form__section"
    :class="{ 'px-8 py-12': !usedInForm, invisible: !showSurvey }"
  >
    <div class="os-form__subtitle">How did we do today?</div>
    <div v-if="!isSubmitted">
      <p class="mb-4">
        {{ surveyQuestions.formsSurvey.question }}
      </p>

      <div class="flex justify-center mb-1">
        <button
          v-for="rating in [1, 2, 3, 4, 5]"
          :key="rating"
          class="flex justify-center py-2 px-2 grow text-stone-400 border-solid border-stone-100 border-1"
          :class="
            selectedRating === rating
              ? 'bg-blue-600 border-none text-stone-50 font-bold'
              : 'bg-stone-50'
          "
          @click="submitRating(rating)"
        >
          {{ rating }}
        </button>
      </div>
      <div class="flex justify-between mb-4">
        <p class="text-stone-500 text-sm">Not at all</p>
        <p class="text-stone-500 text-sm">Extremely</p>
      </div>
      <div v-if="selectedRating">
        <div class="mb-1">
          <InputTextArea
            v-model="surveyAnswer"
            class="survey__text-input"
            :aria-placeholder="placeholderText"
            :placeholder="placeholderText"
            type="text"
            style="width: 100%"
            row="5"
          />
        </div>
        <div>
          <p v-if="!isFAQs" class="text-sm mb-4">
            The feedback you provide helps us to improve our platform. See our
            <NuxtLink :to="faqRoute" target="_blank" class="text-blue-600">
              FAQ page
            </NuxtLink>
            for help.
          </p>
          <p v-else class="text-sm mb-4">
            The feedback you provide helps us to improve our platform.
          </p>
          <div class="flex justify-end">
            <OsButton is-primary @click="submitFeedback()"
              >Send your rating</OsButton
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Thank you for your feedback.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { useStockNotificationStore } from '~/stores/forms/stockNotificationStore'
import { useFormsStore } from '~/stores/forms/formsStore'
import { EFormType } from '~/types/forms'
import { ERoutePageSlugs, ERoutes, PageRoute } from '~/types/routes'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'
import surveyQuestions from '~/constants/surveyQuestions'

export default {
  name: 'MobileFormsSurvey',
  props: {
    formType: {
      type: String as PropType<EFormType>,
      required: false,
      default: ''
    },
    vehicle: {
      type: Object as PropType<NewVehicle | UsedVehicle>,
      required: false,
      default: () => {}
    },
    usedInForm: {
      type: Boolean,
      required: true,
      default: false
    },
    immediate: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(props) {
    const stockNotificationStore = useStockNotificationStore()
    const formsStore = useFormsStore()
    const selectedRating = ref(null)
    const surveyAnswer = ref('')
    const isSubmitted = ref(false)
    const { $dataLayer } = useNuxtApp()

    const vehicle = computed(() => formsStore.getVehicle)

    const survey = ref<null | HTMLElement>(null)

    const timeout: any = null

    onBeforeUnmount(() => {
      if (timeout) clearTimeout(timeout)
    })

    const submitRating = (rating) => {
      selectedRating.value = rating

      if (props.formType) {
        $dataLayer.linkClick({
          pageCategory:
            props.formType === EFormType.Offer
              ? `${props.formType}:next steps`
              : `${props.formType}:thank you`,
          category: 'in_page_survey_rating_click',
          action: rating
        })
      } else {
        $dataLayer.linkClick({
          category: 'in_page_survey_rating_click',
          action: rating
        })
      }
    }

    const submitFeedback = () => {
      isSubmitted.value = true

      setSurveyComplete()

      if (props.formType) {
        $dataLayer.linkClick({
          pageCategory:
            props.formType === EFormType.Offer
              ? `${props.formType}:next steps`
              : `${props.formType}:thank you`,
          category: 'in_page_survey_submit',
          action: '',
          vehicle: vehicleAnalytics(vehicle.value),
          selectionsMade:
            props.formType === EFormType.StockNotification
              ? stockNotificationStore.selectionsMadeDataLayer
              : undefined,
          userFeedback: {
            question: surveyQuestions.formsSurvey.question,
            rating: selectedRating.value,
            comments: surveyAnswer.value
          }
        })
      } else {
        $dataLayer.linkClick({
          category: 'in_page_survey_submit',
          action: '',
          userFeedback: {
            question: surveyQuestions.formsSurvey.question,
            rating: selectedRating.value,
            comments: surveyAnswer.value
          }
        })
      }
    }

    const { stop } = useIntersectionObserver(survey, ([{ isIntersecting }]) => {
      if (isIntersecting) {
        if (props.formType) {
          $dataLayer.eecImpression({
            pageCategory:
              props.formType === EFormType.Offer
                ? `${props.formType}:next steps`
                : `${props.formType}:thank you`,
            category: 'in_page_survey_seen',
            action: ''
          })
        } else {
          $dataLayer.eecImpression({
            category: 'in_page_survey_seen',
            action: ''
          })
        }
        stop()
      }
    })

    const isWithinForm = computed(() => {
      return !!props.formType
    })

    const placeholderText = computed(() =>
      isWithinForm.value
        ? surveyQuestions.formsSurvey.placeholderQuestion
        : isFAQs.value
          ? surveyQuestions.faqSurvey.placeholderQuestion
          : surveyQuestions.resultsSurvey.placeholderQuestion
    )

    const route = useRoute()

    const isFAQs = computed(() => {
      return route.name === ERoutes.FAQ
    })

    const faqRoute = computed<PageRoute>(() => {
      return {
        name: ERoutes.Page,
        params: {
          slug: ERoutePageSlugs.FAQ
        }
      }
    })

    const { showSurvey, setSurveyComplete } = useSurvey(props.immediate)

    return {
      submitRating,
      selectedRating,
      surveyAnswer,
      isSubmitted,
      submitFeedback,
      faqRoute,
      survey,
      surveyQuestions,
      placeholderText,
      isFAQs,
      showSurvey
    }
  }
}
</script>

<style scoped lang="scss">
.survey {
  &__text-input ::placeholder {
    color: $grey--darkest;
    font-size: rem(14);
  }
}
</style>
